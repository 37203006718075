import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { changeLanguage } from '../../store/slices/languageSlice';
import { Switch } from 'antd';

const LanguageSwitcher: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);

    const handleToggle = (checked: boolean) => {
        const newLanguage = checked ? "ar" : "en";
        dispatch(changeLanguage(newLanguage));
    };
    return (
        <>
            <Switch checked={currentLanguage === "ar"} onChange={handleToggle} />
            <p className="mx-2 my-0 keep-me-login">
                {currentLanguage === "en" ? "العربية" : "English"}
            </p>
        </>
    );
};

export default LanguageSwitcher;
