import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CButton from "../../components/common/CButton";
import { Theme } from "../../Theme";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import CInput from "../../components/common/CInput";
import { useMutation } from "react-query";
import { forgot } from "../../services/auth";
import CustomAlert from "../../components/common/CAlert";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import AuthLanguage from "../../components/ui/auth/AuthLanguage";
import LanguageSwitcher from "../../components/ui/LanguageSwitcher";
import AuthIcon from "../../components/ui/auth/AuthIcons";

const Forgot: React.FC = () => {
    const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
    const location = useLocation();
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { type } = location?.state;

    const { mutate, isLoading, isError, error } = useMutation(forgot, {
        onSuccess: (response) => {
            if (response?.statusCode === 200) {
                navigate('/reset', { state: { resetPasswordToken: response?.data?.link?.split('/').pop(), type } });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? (currentLanguage === 'en' ? error?.response?.data?.message : error?.response?.data?.messageAr) : t("An error occurred during login. Please try again."), type: 'error', visible: true });
        },
    });

    const onFinish = (values: any) => {

        mutate({ values, type });
    }

    return (
        <>
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
            <div className="auth-main-div d-flex justify-content-between">
                <div className="auth-form-div mt-5 auth-pad-div">
                    <p className="auth-heading m-0">{t("Forgot Password")}</p>
                    <p className="auth-desc">{t("Enter a registered email address and get a a password reset link to change your password.")}</p>

                    <Form
                        name="forgot"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: t('email_error'),
                                },
                                {
                                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                    message: t('email_validation'),
                                },
                            ]}
                        >
                            <CInput
                                label={t('email_address')}
                                placeholder={t('email_placeholder')}
                            />
                        </Form.Item>

                        <CButton loading={isLoading} disabled={false} className="mt-4 auth-btn" htmlType="submit">{t("Send Link")}</CButton>
                    </Form>
                    <div className="switch-form-div justify-content-center w-100 mt-2">
                        <div className="align-items-center switch-card-div p-2">
                            <LanguageSwitcher />
                        </div>
                    </div>
                </div>
                <AuthLanguage />
            </div>
            <AuthIcon/>
            <p className="reserved-rights mt-3">
                {t("All rights reserved to the National Labor Observatory")}
            </p>
        </>

    );
};

export default Forgot;


