import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CButton from "../../../components/common/CButton";
import { useTranslation } from "react-i18next";
import { Theme } from "../../../Theme";
import CInput from "../../../components/common/CInput";
import { Breadcrumb, Form } from "antd";
import CustomSelect from "../../../components/common/CSelect";
import SuccessModal from "../../../components/common/SuccessModal";
import { useMutation } from "react-query";
import { createEntity } from "../../../services/Entity";
import CustomAlert from "../../../components/common/CAlert";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const CreateEntity: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [phone, setPhone] = useState<string>('');


    const {type, record} = location?.state || {};
    console.log('location type & record ==+>>.', type, record);

    useEffect(() => {
if(record){
    setPhone(record?.mobileNumber);
    form.setFieldsValue({
        name: record?.name,
        entityType: record?.entityType,
        spocEmail: record?.spocEmail,
        mobileNumber: record?.mobileNumber,
        spocName: record?.spocName,

    })
}
    },[])

    const { mutate, isLoading, isError, error } = useMutation(createEntity, {
        onSuccess: (response) => {
            if (response?.statusCode === 200) {
                setSuccess(true);
                setEmail(response?.data?.user?.email);
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.response?.data?.message : t("An error occurred during login. Please try again."), type: 'error', visible: true });
        },
    });

    const validateEmail = (value: string) => {
        return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
    }
    const onFinish = (values: Object) => {
        const obj = {
            ...values,
            ...(record && { entityId: record.id })
          }
        mutate({obj, type});
    }

    const EntityType = [
        { value: 'Giga Project', label: 'Giga Project' },
        { value: 'VRPs', label: 'VRPs' },
        { value: 'Strategy', label: 'Strategy' }];

    return (

        <div className="round-content-div p-4 h-100">
            <div className="d-flex justify-content-between">
                <div>
                    <p className="list-heading m-0">{t(`${type === 'view' ?  'View' : type === 'edit' ?  'Edit' : 'Create' } Entity`)}</p>
                    <Breadcrumb
                    separator=">"
                        items={[
                            {
                                title: t('Entities'),
                            },
                            //   {
                            //     title: <a href="">Application Center</a>,
                            //   },
                            {
                                title: t('Entity Overview'),
                            },
                            {
                                title: t('Entity Listing'),
                            },
                        ]}
                    />
                </div>

            </div>

            <div className="w-50 mt-3">
                <Form
                    name="createEntity"
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    className="row"
                >
                    <div className="col-lg-6">
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the entity name'),
                                },
                            ]}
                        >
                            <CInput
                                label={t('Entity Name')}
                                placeholder={t('Enter Entity Name')}
                                required={true}
                                disabled={type === 'view'}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            name="entityType"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the entity type'),
                                },
                            ]}
                        >

                            <CustomSelect
                                label={t('Entity Type')}
                                options={EntityType}
                                required={true}
                                // value={selectedVendor.id}
                                // onChange={(value) => onChangeVendor(value)}
                                placeholder={t('Select Entity Type')}
                                disabled={type === 'view'}
                            />
                        </Form.Item>
                    </div>

                    <div className="col-lg-6">
                        <Form.Item
                            name="spocEmail"
                            rules={[
                                {
                                    required: true,
                                    message: t('email_validation'),
                                    validator: (_, value) => {
                                        if (validateEmail(value)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(t('email_error'));
                                        }
                                    },
                                },
                            ]}
                        >
                            <CInput
                                label={t('SPOC Email Address')}
                                placeholder={t('Enter SPOC Email Address')}
                                required={true}
                                disabled={type === 'view' || type === 'edit'}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            name="mobileNumber"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the mobile number'),
                                },
                            ]}
                        >
                            <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t('Mobile Number')}<span style={{color:'red'}} >*</span></label>
                            <PhoneInput
                                country={'sa'}
                                onlyCountries={['sa']}
                                placeholder={t('Enter Mobile Number')}
                                value={phone}
                                onChange={(phone) =>  form.setFieldsValue({
                                    mobileNumber  : phone
                                })}
                                disabled={type === 'view'}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            name="spocName"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the SPOC Name'),
                                },
                            ]}
                        >
                            <CInput
                                label={t('SPOC Name')}
                                placeholder={t('Enter SPOC Name')}
                                required={true}
                                disabled={type === 'view'}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            // name="role"
                            rules={[
                                {
                                    required: false,
                                    message: t('Please enter the role'),
                                },
                            ]}
                        >
                            <CInput
                                label={t('Role')}
                                placeholder={t('Enter Role')}
                                value={t("Primary SPOC")}
                                disabled={true}
                                required={true}
                            />
                        </Form.Item>
                    </div>

                  {type !== 'view' && (
                <>
                     <div className="caution-div m-2 w-100">
                        <span className="ant-checkbox-wrapper" style={{fontSize:'12px'}}>{t("An automated email with an invite link will be sent to the Entity Representative")}</span>
                    </div>

                    <div className="mt-2 d-flex">
                        <CButton className="form-btn" loading={isLoading} disabled={false} htmlType="submit">{t(`${type === 'view' ?  'View' : type === 'edit' ?  'Edit' : 'Create' } Entity`)}</CButton>
                        <CButton className="form-btn cancel-btn mx-2" loading={false} disabled={false} onClick={() => navigate('/admin/dashboard/entities')}>{t("Cancel")}</CButton>
                    </div>
                    </>
                )}
                </Form>
            </div>
            <SuccessModal
                visible={success}
                heading={t(`Entity ${type === 'edit'  ? "Updated"  : "Added"}`)}
                message={ type === 'edit'  ? t("You have Successfully updated the User") :
                    <>
                      {t("You have successfully added a new entity and a signup link is shared at")} <strong>{email}</strong>
                    </>
                  }
                onOk={() => navigate('/admin/dashboard/entities')}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </div>


    );
};

export default CreateEntity;
