import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en/translation.json";
import arTranslation from "./locales/ar/translation.json";

const savedLanguage = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next) // Initialize React i18next
  .init({
    resources: {
      en: { translation: enTranslation },
      ar: { translation: arTranslation },
    },
    lng: savedLanguage,
    fallbackLng: "en",
    supportedLngs: ["en", "ar"],
    debug: false,
    interpolation: {
      escapeValue: false, // React handles escaping
    },
  });

export default i18n;
