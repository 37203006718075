import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CButton from "../../components/common/CButton";
import { Theme } from "../../Theme";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import OtpInput from 'react-otp-input';
import { otpVerification, resendOtp } from "../../services/auth";
import { useMutation } from "react-query";
import CustomAlert from "../../components/common/CAlert";
import LanguageSwitcher from "../../components/ui/LanguageSwitcher";
import AuthLanguage from "../../components/ui/auth/AuthLanguage";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import AuthIcon from "../../components/ui/auth/AuthIcons";
import { otpUserVerification } from "../../services/User";


const Otp: React.FC = () => {
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes = 120 seconds
  const [timerExpired, setTimerExpired] = useState(false);
  const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
    message: '',
    type: 'success',
    visible: false,
  });
  const { email, type } = location?.state || {};

  useEffect(() => {
    // Exit early if timer expired
    if (timeLeft <= 0) {
      setTimerExpired(true);
      return;
    }

    // Set up interval to decrease timeLeft by 1 second
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clear interval when the component unmounts or when timeLeft reaches 0
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const { mutate: resend, isLoading: resendLoading, isError: resendIsError, error: resendError } = useMutation(resendOtp, {
    onSuccess: (response) => {
      if (response?.statusCode === 200) {
        setAlert({ message: t("Please check your email"), type: 'success', visible: true });
      }
    },
    onError: (error: any) => {
      setAlert({ message: error ? (currentLanguage === 'en' ? error?.response?.data?.message : error?.response?.data?.messageAr) : t("An error occurred during login. Please try again."), type: 'error', visible: true });
    },
  });


  const reSendOtp = () => {
    let obj = {
      email,
    }
    resend({ obj, type });
  }


  const { mutate, isLoading } = useMutation(type === 'admin' ? otpVerification : otpUserVerification, {
    onSuccess: (response) => {
      if (response.statusCode === 200) {
        navigate('/registrationComplete');
      }
    },
    onError: (error: any) => {
      setAlert({ message: error ? (currentLanguage === 'en' ? error?.response?.data?.message : error?.response?.data?.messageAr) : t("An error occurred during login. Please try again."), type: 'error', visible: true });
    },
  });

  const onFinish = (values: any) => {
    if (otp?.length === 4) {
      const obj = {
        email: location.state,
        otp
      }
      mutate(obj);
    } else {
      if(otp?.length === 0){
        setAlert({ message: t("Please enter the OTP"), type: 'error', visible: true });
      }else{
        setAlert({ message: t("Please enter the correct OTP"), type: 'error', visible: true });
      }

    }
  }

  return (
    <>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
      <div className="auth-main-div d-flex justify-content-between">
        <div className="auth-form-div mt-5">
          <p className="auth-heading m-0">{t("Verification")}</p>
          <p className="auth-desc">A verification code has been sent to <strong>{location.state}</strong> Please check your inbox</p>

          <Form
            name="otp"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              containerStyle={{ justifyContent: 'space-between' }}
              placeholder="X"
              inputStyle={{ borderRadius: '8px', height: '56px', width: '70px', border: '1px solid #D5D7DA', fontSize: '16px', fontFamily: 'sf-regular' }}
              renderInput={(props) => <input {...props} />}
            />
            <CButton loading={isLoading} disabled={false} className="mt-4 auth-btn" htmlType="submit">{t("Confirm Code")}</CButton>
            {!timerExpired ? (
              <p style={{ textAlign: 'center' }} className="mt-4 m-0 click-drag ">{t("Resend Code")} <span style={{ color: Theme.colors.gray1 }}>{formatTime(timeLeft)}</span></p>
            ) : (
              <p style={{ textAlign: 'center' }} className="mt-4 m-0 click-drag cursor-pointer" onClick={() => reSendOtp()}>{t("Resend Code")}</p>
            )}
          </Form>
          <div className="switch-form-div justify-content-center w-100 mt-2">
            <div className="align-items-center switch-card-div p-2">
              <LanguageSwitcher />
            </div>
          </div>
        </div>
        <AuthLanguage />
      </div>
      <AuthIcon/>
      <p className="reserved-rights mt-3">
        {t("All rights reserved to the National Labor Observatory")}
      </p>
    </>

  );
};

export default Otp;


