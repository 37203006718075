import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CButton from "../../components/common/CButton";
import { Theme } from "../../Theme";
import { useTranslation } from "react-i18next";
import { Checkbox, CheckboxProps, Form, Switch } from "antd";
import PasswordInput from "../../components/common/PasswordInput";
import CInput from "../../components/common/CInput";
import AuthLanguage from "../../components/ui/auth/AuthLanguage";
import { useMutation } from "react-query";
import { login } from "../../services/auth";
import CustomAlert from "../../components/common/CAlert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { signin } from "../../store/slices/authSlice";
import LanguageSwitcher from "../../components/ui/LanguageSwitcher";
import AuthIcon from "../../components/ui/auth/AuthIcons";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const [loggedIn, setLoggedIn] = useState(false);
  const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
    message: '',
    type: 'success',
    visible: false,
  });
  const { type } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    try {
      const authUser = localStorage.getItem("authToken");
      if (authUser) {
        if (type === "user") {
          navigate(`/${type}/dashboard/users`);
        } else {
          navigate(`/${type}/dashboard/entities`);
        }
      }
    } catch (error) {
      console.error("Error parsing local storage data", error);
    }
    document.body.classList.add("auth-body-bg");
    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, [navigate]);


  const onChange: CheckboxProps['onChange'] = (e) => {
    setLoggedIn(e.target.checked);
  };


  const { mutate, isLoading, isError, error } = useMutation(login, {
    onSuccess: (response) => {
      if (response?.statusCode === 200) {

        const { roleName } = response?.data?.user?.role;
        setAlert({ message: 'Login successful!', type: 'success', visible: true });
        localStorage.setItem('authToken', response.data.userAuth);

        const userData = {
          user: response.data.user,
          token: response.data.userAuth,
        };
        dispatch(signin(userData));
        if (type === "user") {
          navigate(`/${type}/dashboard/users`);
        } else {
          navigate(`/${type}/dashboard/entities`);
        }
      }
    },
    onError: (error: any) => {
      setAlert({ message: error ? (currentLanguage === 'en' ? error?.response?.data?.message : error?.response?.data?.messageAr) : t("An error occurred during login. Please try again."), type: 'error', visible: true });
    },
  });


  const onFinish = (values: any) => {
    let obj = {
      ...values,
      keepLogin: loggedIn ? 1 : 0
    }
    mutate({ obj, type });
  }

  return (
    <>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
      <div className="auth-main-div d-flex justify-content-between">
        <div className="auth-form-div mt-5">
          <p className="auth-heading m-0">{t("sign_in")}</p>
          <p className="auth-desc">{t("signIn_description")}</p>

          <Form
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t('email_error'),
                },
                {
                  pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t('email_validation'),
                },
              ]}
            >
              <CInput
                label={t('email_address')}
                placeholder={t('email_placeholder')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: t('password_error') },
                // { min: 8, message: t('password_validation') },
                {
                 pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+[\]{};:'",.<>?\/|`~¢£¤¥€₹₩₱₿÷×√∞≈≠≤≥¬∧∨⊂⊃∈∉∪∩←↑→↓↔⇒⇔§©®™☑☹☺♥]).+$/,
                  message: t('password_regex'),
                },
              ]}
            >
              <PasswordInput
                label={t('password')}
                placeholder={t('password_placeholder')}
              />
            </Form.Item>
            <div className="d-flex justify-content-between mt-3">
              <Checkbox onChange={onChange}>{t("Keep me logged in")}</Checkbox>
              <span className="forgot-password" onClick={() => navigate('/forgot', { state: { type } })}>{t("Forgot Password")}</span>
            </div>
            <CButton loading={isLoading} disabled={false} className="mt-2 auth-btn" htmlType="submit">{t("sign_in")}</CButton>
          </Form>


          <div className="switch-form-div justify-content-center w-100 mt-2">
            <div className="align-items-center switch-card-div p-2">
              <LanguageSwitcher />
            </div>
          </div>
        </div>
        <AuthLanguage />
      </div>
    <AuthIcon/>
      <p className="reserved-rights mt-3">
        {t("All rights reserved to the National Labor Observatory")}
      </p>
    </>

  );
};

export default Login;


