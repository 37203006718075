import instance from "./api/api_instance";

interface loginParams {
type?: string;
  [key: string]: any; // Allows additional properties if needed
}

export const login = async (credentials: loginParams) => {
  const { type, ...rest } = credentials;
  const response = await instance.post(`${type}/login`, rest?.obj);
  return response.data;
};

export const register = async (credentials: object) => {
  const response = await instance.post('entity/set-password', credentials);
  return response.data;
};

export const otpVerification = async (credentials: object) => {
  const response = await instance.post('entity/verify-otp', credentials);
  return response.data;
};

// export const resendOtp = async (credentials: object) => {
//   const response = await instance.post('admin/resend-otp', credentials);
//   return response.data;
// };

export const resendOtp = async (credentials: loginParams) => {
  const { type, ...rest } = credentials;
  const response = await instance.post(`${type}/resend-otp`, rest?.obj);
  return response.data;
};

export const forgot = async (credentials: loginParams) => {
  const { type, ...rest } = credentials;
  const response = await instance.post(`${type}/forgot-password`, rest?.values);
  return response.data;
};

export const reset = async (credentials: loginParams) => {
  const { type, ...rest } = credentials;
  const response = await instance.post(`${type}/reset-password`, rest);
  return response.data;
};