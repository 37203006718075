import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CButton from "../../components/common/CButton";
import { Theme } from "../../Theme";
import { useTranslation } from "react-i18next";
import AuthIcon from "../../components/ui/auth/AuthIcons";

const RegistrationComplete: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <img
          src={Theme.images.img_national_labour}
          className="national-labour-icn"
          alt="National Labour"
        />
        <div className="auth-form-div d-flex flex-column registration-auth-modal align-items-center mt-2" style={{ padding: '30px', textAlign: 'center' }}>
          <img
            src={Theme.images.img_registration_done}
            className=""
            alt="National Labour"
            style={{ width: '150px' }}
          />
          <p className="auth-heading m-0">{t("Registration Complete")}</p>
          <p className="auth-desc w-75">{t("Click Continue to start using JCI Portal and submit your Job Creation Data to NLO.")}</p>

          <CButton loading={false} disabled={false} className="mt-4 auth-btn w-75" onClick={() => navigate('/user/login')}>{t("Proceed to Login")}</CButton>
        </div>

      </div>
   <AuthIcon/>
      <p className="reserved-rights mt-3">
        {t("All rights reserved to the National Labor Observatory")}
      </p>
    </>

  );
};

export default RegistrationComplete;


