import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CButton from "../../../components/common/CButton";
import { useTranslation } from "react-i18next";
import CInput from "../../../components/common/CInput";
import { Breadcrumb, Form } from "antd";
import CustomSelect from "../../../components/common/CSelect";
import SuccessModal from "../../../components/common/SuccessModal";
import { useMutation } from "react-query";
import CustomAlert from "../../../components/common/CAlert";
import { updateUserStatus } from "../../../services/User";


const ViewRequest: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [status, setStatus] = useState('');
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    const { record } = location?.state || {};

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                name: record?.name,
                email: record?.email,
                entityName: record?.entityName,
                roleId: record?.roleId.toString(),
                requestedBy: record?.requestedBy,

            })
        }
    }, [])

    const { mutate, isLoading, isError, error } = useMutation(updateUserStatus, {
        onSuccess: (response) => {
            if (response?.statusCode === 200) {
                setSuccess(true);
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.response?.data?.message : t("An error occurred during login. Please try again."), type: 'error', visible: true });
        },
    });

    const validateEmail = (value: string) => {
        return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
    }

    const changeStatus = (status: string) => {
        setStatus(status);
        const obj = {
            status,
            userId: record?.id
        }
        mutate(obj);
    }

    const Roles = [
        { value: '4', label: t("Primary SPOC") },
        { value: '5', label: t("Secondary SPOC") },
        { value: '6', label: t("Standard Role") }];

    return (

        <div className="round-content-div p-4 h-100">
            <div className="d-flex justify-content-between">
                <div>
                    <p className="list-heading m-0">{t("Request Detail")}</p>
                    <Breadcrumb
                    separator=">"
                        items={[
                            {
                                title: t("Requests"),
                            },
                            {
                                title: t('Requests Overview'),
                            },
                            {
                                title: t('Request Detail'),
                            },
                        ]}
                    />
                </div>

            </div>

            <div className="w-50 mt-3">
                <Form
                    name="createEntity"
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    autoComplete="off"
                    className="row"
                >
                    <div className="col-lg-6">
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the user name'),
                                },
                            ]}
                        >
                            <CInput
                                label={t('User Name')}
                                placeholder={t('Enter User Name')}
                                disabled={true}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: t('email_validation'),
                                    validator: (_, value) => {
                                        if (validateEmail(value)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(t('email_error'));
                                        }
                                    },
                                },
                            ]}
                        >
                            <CInput
                                label={t('User Email')}
                                placeholder={t('Enter User Email')}
                                disabled={true}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-12">
                        <Form.Item
                            name="entityName"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the entity name'),
                                },
                            ]}
                        >
                            <CInput
                                label={t('Entity Name')}
                                placeholder={t('Enter Entity Name')}
                                disabled={true}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            name="roleId"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the role'),
                                },
                            ]}
                        >
                            <CustomSelect
                                label={t('Role')}
                                options={Roles}
                                placeholder={t('Select Role')}
                                disabled={true}
                            />
                        </Form.Item>
                    </div>

                    <div className="col-lg-6">
                        <Form.Item
                            name="requestedBy"
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter the request by'),
                                },
                            ]}
                        >
                            <CInput
                                label={t('Requested By')}
                                placeholder={t('Enter Requested By')}
                                disabled={true}
                            />
                        </Form.Item>
                    </div>

                    <div className="caution-div m-2 w-100">
                        <span className="ant-checkbox-wrapper">{t("An automated email with an invite link will be sent to the user's email.")}</span>
                    </div>

                    <div className="mt-2 d-flex">
                        <CButton className="form-btn success-btn" loading={isLoading && status === 'active'} disabled={false} onClick={() => changeStatus('active')}>{t("Approve")}</CButton>
                        <CButton className="form-btn mx-2" loading={isLoading && status === 'disapprove'} disabled={false} onClick={() => changeStatus('disapprove')}>{t("Disapprove")}</CButton>
                        <CButton className="form-btn cancel-btn mx-2" loading={false} disabled={false} onClick={() => navigate('/admin/usersRequest')}>{t("Cancel")}</CButton>
                    </div>
                </Form>
            </div>
            <SuccessModal
                visible={success}
                heading={t("Status Updated")}
                message={`${t("You have successfully update the user Status")}`}
                onOk={() => navigate('/admin/usersRequest')}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </div>


    );
};

export default ViewRequest;
