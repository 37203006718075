import React, { useEffect, useState } from "react";
import { Theme } from "../../Theme";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../store/slices/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useLocation } from "react-router-dom";
import {getProfile} from '../../services/Entity';
import { useMutation } from "react-query";
import CustomAlert from "../common/CAlert";
import { signin } from "../../store/slices/authSlice";

const Header: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
    const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
    const location = useLocation();
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    const handleToggle = (checked: boolean) => {
        const newLanguage = checked ? "ar" : "en";
        dispatch(changeLanguage(newLanguage));
      };

      const { mutate, isLoading, isError, error } = useMutation(getProfile, {
        onSuccess: (response) => {
            if (response?.statusCode === 200) {
                console.log('api response ===>>>>', response?.data?.user);
                const token = localStorage.getItem('authToken') || '';
                const userData = {
                    user: response.data.user,
                    token: token
                  };
                  dispatch(signin(userData));
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.response?.data?.message : t("An error occurred during login. Please try again."), type: 'error', visible: true });
        },
    });


      useEffect(() => {
        if(!isAuthenticated){
            mutate({});
        }
      },[])
      
    return (
        <>
          <div className="px-4 header-portal-div d-flex align-items-center justify-content-between">
            <p className="m-0 header-heading">{location.pathname.split('/')[1] === 'admin' ? t("National Labor Observatory") : t("Entity Name")}</p>
            <div className="d-flex">
              <img className="mx-4" src={Theme.icons.icn_notification_bell} />
              <img className="mx-4 cursor-pointer" src={Theme.icons.icn_switch_languages} onClick={() => handleToggle(currentLanguage === 'en' ? true : false)} />
              <img className="mx-4" src={Theme.icons.icn_customer_service_line} />
              <img className="mx-4" src={Theme.icons.icn_settings} />

            </div>
          </div>
          <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>

    );
};

export default Header;


