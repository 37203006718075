import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Theme } from "../../Theme";
import { useTranslation } from "react-i18next";
import { Menu } from "antd";
import Header from "../../components/ui/Header";

const PortalScreen: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState('home');
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleLogout = () => {
    localStorage?.clear();
    navigate('/');
  }

  

  const getImage = (imgPath: string, selectedImgPath?: string, isSelected?: boolean) => (
    <img
      src={isSelected && selectedImgPath ? selectedImgPath : imgPath}
      style={{ height: '20px' }}
    />
  );

  const userItems = [
    {
      key: 'home',
      icon: getImage(Theme.icons.icn_home, Theme.icons.icn_home_black, selectedKey === 'home'),
      label: 'Home',
      path: '/dashboard/users',
    },
  ]

  const items = [
    {
      key: 'home',
      icon: getImage(Theme.icons.icn_home, Theme.icons.icn_home_black, selectedKey === 'home'),
      label: 'Home',
      path: '/dashboard/entities',
    },
    {
      key: 'requests',
      icon: getImage(Theme.icons.icn_message_square, Theme.icons.icn_message_square_black, selectedKey === 'requests'),
      label: 'Requests',
      path: '/usersRequest',
    },
    {
      key: 'strategies',
      icon: getImage(Theme.icons.icn_strategies, Theme.icons.icn_log_out, selectedKey === 'strategies'),
      label: 'Strategies',
      path: '/strategies',
    },
    {
      key: 'users',
      icon: getImage(Theme.icons.icn_user, Theme.icons.icn_log_out, selectedKey === 'users'),
      label: 'Users',
      path: '/users',
    },


    {
      key: 'reports',
      icon: getImage(Theme.icons.icn_trending, Theme.icons.icn_home_black, selectedKey === 'reports'),
      label: 'Reports',
    },
  ];

  const handleMenuClick = (e: any) => {
    const selectedItem = items.find(item => item.key === e.key);
    if (selectedItem && selectedItem.path) {
      navigate(`/admin${selectedItem.path}`); // Navigate to the path
    }
    setSelectedKey(e.key);
  };

  return (
    <>
      <div className="main-portal-div">
        <div className="sider-portal-div d-flex flex-column align-items-center justify-content-between">
          <div className="side-menu">

            <img src={Theme.images.img_national_labour} style={{ width: '200px' }} />

            <Menu
              mode="inline"
              className='mt-4'
              selectedKeys={[selectedKey]}
              onClick={handleMenuClick}
              // openKeys={openKeys} // Control openKeys here
              // onOpenChange={onOpenChange}
              items={location?.pathname?.split('/')[1] === 'admin' ? items : userItems}
            />

          </div>
          <div style={{ width: '90%' }}>
            <div className="d-flex flex-column align-items-center mb-3">
              <img src={Theme.images.img_placeholder} className="img-profile" />
              <span className="user-name">Mohammad Alassafi</span>
              <span className="user-email">Assigned Role</span>
            </div>

            <div className="d-flex justify-content-between mx-4 py-4 cursor-pointer logout-div" onClick={() => handleLogout()}>
              <span className="logout">Logout</span>
              <img src={Theme.icons.icn_log_out} />
            </div>
          </div>
        </div>
        <div className="main-content-portal-div">
        <Header/>
          <div className="content-portal-div">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalScreen;
