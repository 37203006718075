import instance from "./api/api_instance";
  
  interface UserListParams {
    pageNumber: number;
    pageSize: number;
    search?: string; // Make this optional if it may not always be provided
    status?: string; // Same here
    [key: string]: any; // Allows additional properties if needed
}

export const getUserList = async (credentials: UserListParams) => {
    const { pageNumber, pageSize, ...rest } = credentials;
    const response = await instance.post('user/list', rest, {
        params: { page: pageNumber, per_page: pageSize },
    });
    return response.data;
};
  
  interface userParams {
    type?: string;
      [key: string]: any; // Allows additional properties if needed
    }
  export const createUser = async (credentials: userParams) => {
    const { type, ...rest } = credentials;
      const response = await instance.post(`user/${type === 'edit' ? 'update' : 'create'}`, rest?.obj);
      return response.data;
    };

  export const updateUserStatus = async (credentials: object) => {
    const response = await instance.post('user/change-status', credentials);
    return response.data;
  };

  export const verifyUserLink = async (credentials: object) => {
    const response = await instance.post('user/verify-link', credentials);
    return response.data;
  };

  export const registerUser = async (credentials: object) => {
    const response = await instance.post('user/set-password', credentials);
    return response.data;
  };

  export const otpUserVerification = async (credentials: object) => {
    const response = await instance.post('user/verifyOTP', credentials);
    return response.data;
  };