import React from "react";
import { Theme } from "../../../Theme";

const AuthIcon: React.FC = () => {
    return (
        <>
       <div className="d-flex align-items-center mt-3 ad-image-container">
        <img className="ad-icon" src={Theme.images.img_ad_two} alt="Ad Two" />
        <img className="ad-icon" src={Theme.images.img_ad_one} alt="Ad One" />
        <img className="ad-icon" src={Theme.images.img_ad_seven} alt="Ad Seven" />
        <img className="ad-icon" src={Theme.images.img_ad_three} alt="Ad Three" />
        <img className="ad-icon" src={Theme.images.img_ad_six} alt="Ad Six" />
        <img className="ad-icon" src={Theme.images.img_ad_five} alt="Ad Five" />
      </div>
        </>

    );
};

export default AuthIcon;


