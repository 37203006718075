import React, { ComponentType, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Auth/Login';
import PortalScreen from './pages/Layout/PortalScreen';
// import i18n from './i18next';
import EntityOverview from './pages/Admin/Entity/EntityOverview';
import Otp from './pages/Auth/Otp';
import RegistrationComplete from './pages/Auth/RegistrationComplete';
import Register from './pages/Auth/Register';
import Forgot from './pages/Auth/Forgot';
import Reset from './pages/Auth/Reset';
import CreateEntity from './pages/Admin/Entity/CreateEntity';
import UserListing from './pages/Users/User/User';
import CreateUser from './pages/Users/User/CreateUser';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import { useTranslation } from 'react-i18next';
import UserRequest from './pages/Admin/Request/UserRequest';
import ViewRequest from './pages/Admin/Request/ViewRequest';


interface ProtectedRouteProps {
  element: ComponentType<any>; // Type for a React component
  [key: string]: any; // Allow additional props
}


function App() {
  const { t, i18n } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  
  // const user = useSelector((state: RootState) => state?.auth?.user);
  const location = useLocation();

  // console.log('my login user===>>>', user?.roleName);

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
    document.documentElement.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
  }, [currentLanguage, i18n]);
  
  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element: Element, ...rest }) => {
    const authuser = localStorage.getItem('authToken');

    return authuser  ? <Element {...rest} /> : <Navigate to="/" />;
  };
  
  return (
    <>
    <Routes>

    <Route path="/" element={<Navigate to="admin/login" />} />

    <Route path=':type/login' element={<Login />} />
    <Route path='forgot' element={<Forgot />} />
    <Route path='reset' element={<Reset />} />
    <Route path='register' element={<Register />} />
    <Route path='otp' element={<Otp />} />
    <Route path='registrationComplete' element={<RegistrationComplete />} />
    <Route path=':type' element={ <ProtectedRoute element={PortalScreen} />} >
    {location?.pathname?.split('/')[1] === 'admin' 
    // && user?.roleName === 'admin'
    ? (
      <>
    {/* Admin Routes */}
    <Route path='dashboard/entities' element={<EntityOverview />} /> 
    <Route path='entity/:type' element={<CreateEntity />} /> 
    <Route path='usersRequest' element={<UserRequest />} /> 
    <Route path='viewUserRequest' element={<ViewRequest />} /> 
    </>

    ): (
      <>
    {/* Admin Routes */}
    <Route path='dashboard/users' element={<UserListing />} /> 
    <Route path='users/:type' element={<CreateUser />} />
    </>
    )}
    </Route>
    <Route path="*" element={<h1 className="t-center">Not Found</h1>}></Route> 
    </Routes>
    </>
   
  );
}

export default App;
