import instance from "./api/api_instance";


interface entityParams {
  type?: string;
    [key: string]: any; // Allows additional properties if needed
  }
export const createEntity = async (credentials: entityParams) => {
  const { type, ...rest } = credentials;
    const response = await instance.post(`entity/${type === 'edit' ? 'update' : 'create'}`, rest?.obj);
    return response.data;
  };

  export const verifyLink = async (credentials: object) => {
    const response = await instance.post('entity/verify-entity-link', credentials);
    return response.data;
  };
  
  interface EntityListParams {
    pageNumber: number;
    pageSize: number;
    search?: string; // Make this optional if it may not always be provided
    entityType?: string; // Same here
    [key: string]: any; // Allows additional properties if needed
}

export const getEntityList = async (credentials: EntityListParams) => {
    const { pageNumber, pageSize, ...rest } = credentials;
    const response = await instance.post('entity/list', rest, {
        params: { page: pageNumber, per_page: pageSize },
    });
    return response.data;
};
  
export const getProfile = async (credentials: object) => {
  const response = await instance.post('admin/profile', credentials);
  return response.data;
};