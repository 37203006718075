import React from "react";
import { Theme } from "../../../Theme";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher";

const Login: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="auth-detail-div mt-4 px-2">
                <div className="align-items-center switch-div p-2">
                    <LanguageSwitcher/>
                </div>
                <div className="job-creation-div px-3 py-1">
                    <p className="job-creation">
                        {t("Jobs Creation Platform (JCP)")}
                    </p>
                </div>
                <img
                    src={Theme.images.img_national_labour}
                    className="national-labour-icn"
                    alt="National Labour"
                />
                <p className="auth-detail-description">
                    {t("job_creation_desc")}
                </p>
            </div>
        </>

    );
};

export default Login;


