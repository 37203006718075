import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CButton from "../../../components/common/CButton";
import { useTranslation } from "react-i18next";
import { Theme } from "../../../Theme";
import CInput from "../../../components/common/CInput";
import { Breadcrumb, Button, Menu, Dropdown, Tag } from "antd";
import { FilterOutlined, DownOutlined } from '@ant-design/icons';
import { ColumnsType } from "antd/es/table";
import CTable from "../../../components/common/CTable";
import { useMutation } from "react-query";
import CustomAlert from "../../../components/common/CAlert";
import moment from "moment";
import _ from 'lodash';
import { getUserList } from "../../../services/User";
import { CapFirstLetter } from "../../../utils/function";

const UserListing: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState<any>({ list: [], pagination: {} });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const pageNumber = Number(searchParams.get('PageNumber')) || 1;
    const pageSize = Number(searchParams.get('PageSize')) || 10;
    const search = searchParams.get('Search') || "";
    const status = searchParams.get('Status') || "";
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    const { mutate, isLoading, isError, error } = useMutation(getUserList, {
        onSuccess: (response) => {
            if (response?.statusCode === 200) {
                console.log('api response ===>>>>', response?.data?.list);
                setData({ ...data, list: response?.data?.list, pagination: response?.data?.pagination });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.response?.data?.message : t("An error occurred during login. Please try again."), type: 'error', visible: true });
        },
    });

    const handleSearchChange = _.debounce((e: any) => {
        setSearchParams({ Status: status, PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value }, { replace: true });
    }, 1000)


    const handleMenuClick = (e: any) => {
        setSearchParams({ Status: e.key, PageNumber: '1', PageSize: pageSize.toString(), Search: search }, { replace: true });
    };

    const handleTableChange = (page: number, pageSize: number) => {
        setSearchParams({ Status: status, PageNumber: page.toString(), PageSize: pageSize.toString() }, { replace: true });
    };

    const handleMenuTable = (key: string, record: any) => {
        if(key === 'view'){
            navigate(`/user/users/${key}`, {state: { type: key, record}})
        }
        if(key === 'edit'){
            navigate(`/user/users/${key}`, {state: { type: key, record}})
        }
        console.log(`Selected action: ${key} for record:`, record);
    };

    useEffect(() => {
        mutate({ search, status, pageNumber, pageSize });
    }, [search, status, pageNumber, pageSize])

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="pending">{t("Pending")}</Menu.Item>
            <Menu.Item key="disapprove">{t("Disapprove")}</Menu.Item>
            <Menu.Item key="active">{t("Active")}</Menu.Item>
            <Menu.Item key="inactive">{t("Inactive")}</Menu.Item>
        </Menu>
    );

    const MenuFunction = (record: any) => (
        <Menu onClick={({ key }) => handleMenuTable(key, record)}>
            <Menu.Item key="edit">
                Edit
            </Menu.Item>
            <Menu.Item key="view">
                View
            </Menu.Item>
        </Menu>
    );

    const columns: ColumnsType = [
        {
            title: t("User ID"),
            dataIndex: 'id',
            key: 'id',
            align: 'center'
        },
        {
            title: t("User Names"),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
            // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        },
        {
            title: t("Email Address"),
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: t("Assigned Role"),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text, record, index) => (
                <Tag className="px-2 user-email" style={{ borderRadius: '25px', }}
                    color={record?.role?.roleName === 'primary_spoc' ? Theme.colors.primary : record?.role?.roleName === 'secondary_spoc' ? Theme.colors.secondary_spoc : Theme.colors.blue}
                >{record?.role?.roleName === 'primary_spoc' ? t('Primary SPOC') : record?.role?.roleName === 'secondary_spoc' ? t('Secondary SPOC') : t("Entity User")}</Tag>

            ),
        },
        {
            title: t("Status"),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text, record, index) => (
                <Tag className="px-2 user-email" style={{ borderRadius: '25px', }} color={text === 'active' ? Theme.colors.success : text === 'pending' ? Theme.colors.orange : text === 'disapprove' ? Theme.colors.red : Theme.colors.gray}>{CapFirstLetter(text)}</Tag>

            ),
        },
        {
            title: t("Creation Date"),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record, index) => (
                <span>{moment(text).format("MMMM DD, YYYY")}</span>
            ),
        },
        {
            title: t('Action'),
            render: (text, record, index) => (
                <Dropdown overlay={MenuFunction(record)} trigger={['click']}>
                    <img src={Theme.icons.icn_action} alt="icn_action" />
                </Dropdown>
            ),
            key: 'action',
            align: 'center'
        },
    ];

    return (
        <div className="round-content-div h-100">
            <div className=" px-4 d-flex justify-content-between">
                <div>
                    <p className="list-heading m-0">{t("User Listing")}</p>
                    <Breadcrumb
                    separator=">"
                        items={[
                            {
                                title: t("Users"),
                            },
                            {
                                title: t('Users Overview'),
                            },
                        ]}
                    />
                </div>
                <div className="filter-div">

                    <CInput
                        prefix={
                            <img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />
                        }
                        style={{ borderColor: 'grey' }}
                        className="searchInput"
                        placeholder={t("Search")}
                        onChange={handleSearchChange}
                    />

                    <Dropdown overlay={menu} trigger={['click']} className="filter-btn">
                        <Button icon={<FilterOutlined />} style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="d-flex justify-content-between w-100">
                                <span>{status === '' ? t('Filters') : CapFirstLetter(status)}</span>
                                <DownOutlined />
                            </div>
                        </Button>
                    </Dropdown>


                    <CButton
                        className="add-button"
                        onClick={() => navigate('/user/users/add')}
                        style={{
                            backgroundColor: Theme.colors.secondary
                        }}
                    >
                        <img src={Theme.icons.icn_add_user_plus} />
                        <span className="mx-1 logout">{t("Create User")}</span>
                    </CButton>
                </div>
            </div>

            <CTable
                columns={columns}
                dataSource={data?.list}
                pagination={{
                    showSizeChanger: false,
                    showQuickJumper: false,
                    current: data?.pagination.page,
                    pageSize: data?.pagination.per_page,
                    total: data?.pagination.count,
                    onChange: handleTableChange,
                    showTotal: (total: number, range: [number, number]) => (
                        <p>{`${range[0]} - ${range[1]} of ${total}`}</p>
                    ),
                    itemRender: (page, type, originalElement) => {
                        if (type === 'prev') {
                            return <CButton style={{ color: Theme.colors.white }}>{t("< Back")}</CButton>;
                        }
                        if (type === 'next') {
                            return <CButton style={{ color: Theme.colors.white }}>{t("Next >")}</CButton>;
                        }
                        return originalElement;
                    }
                }}
                loading={isLoading}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </div>
    );
};

export default UserListing;
